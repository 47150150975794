#login{
    background-color:darkgrey;
    background-image: url('https://mayaland-src.s3.us-east-2.amazonaws.com/login3.jpg');
    background-size:cover;
    
    
    
}
.fixContainer{
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    z-index: 1;
}


.contLogin{
    background:rgb(255, 255, 255); 
    min-height: 50vh;
    margin-top: 10%;
    margin-bottom: 5%;
    border: 0.5px solid rgb(182, 182, 182);
    border-radius: 40px;
    z-index: 2;
    width: 55%;
}

.userImg{
    width: 200px;
    height: auto;
}

.userImg img{
    width: 100%;
}

.signin-btn {
    background-color: #1976d2;
    color: #fff;
    border-radius: 4px;
    padding: 6px 16px;
    box-shadow: 0 3px 5px rgba(0,0,0,0.2);
    transition: background-color 0.3s, box-shadow 0.3s;
    font-size: 1rem;
    width: 100%;
}
.signin-btn:hover {
    background-color: #1565c0;
    box-shadow: 0 4px 10px rgba(0,0,0,0.3);
    color: #fff;
}
.signin-btn:focus {
    background-color: #1565c0;
    box-shadow: 0 0 0 2px rgba(25,118,210,0.2);
}

.login-title {
    color: #333;
    line-height: 2.25rem;
    margin-bottom: 1rem;
}

.login-text {
    color: #666;
    line-height: 1.5rem;
    margin-bottom: 2rem;
}

.login-input {
    width: 100%;
    padding: 12px 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    background-color: transparent;
    transition: all 0.3s ease;
    color: #333;
    font-family: 'Roboto', sans-serif;
}

.login-input-label {
    top: 12px;
    left: 10px;
    font-size: 1.05rem;
    color: #9e9e9e;
    font-family: 'Roboto', sans-serif;
    transition: all 0.3s ease;
    pointer-events: none;
}
.main-content {
    flex-grow: 1;
}

@media (max-width:768px) {
    .fixContainer{
        padding: 2rem 1rem;
    }

    .contLogin {
        padding: 1.5rem;
        width: 100%;
        max-width: 350px;
    }
}
@media screen and (max-width:614px) {
    .dissapearObject {
        display: none;
    }
}

@media screen and (min-width:614px) {
    .appearMovileObject {
        display: none;
    }

}

.especialButtonClose {
    background: none;
    border-color: none;
    border: none;
    font-size: 35px;
}


.especialButtonClose {
    margin-bottom: 10px;
}

.generalContainer {
    padding-top: 40px;
    margin-bottom: 1px;
}

.backLeyendSoftGreen {
    background: #6bb974c8;

}
.color-box{
    width: 20px;
    height: 20px;

    display: inline-block;
    margin-right: 10px;
  }

.backLeyendSoftYellow {
    background: #e6e183c8;

}

.backLeyendSoftRed {
    background: #cb7474c8;

}



.posIndex {
    z-index: 2;
}

.notcounted {
    background-color: rgba(45, 48, 48, 0.578);
}

.correctCount {
    background-color: rgba(12, 82, 2, 0.578);
}

.listDecoratorNone {
    list-style-type: none;
    display: flex;
    padding: 0;
}



.negativeCount {
    background-color: rgba(119, 30, 30, 0.578);
}

.positiveCount {
    background-color: rgba(2, 87, 93, 0.578);
}


.notcounted2 {
    background-color: rgba(45, 48, 48, 0.493);
    color: rgb(255, 255, 255);
}

.correctCount2 {
    background-color: rgba(11, 82, 2, 0.581);
    color: rgb(255, 255, 255);
}

.negativeCount2 {
    background-color: rgba(119, 30, 30, 0.626);
    color: rgb(255, 255, 255);
}

.positiveCount2 {
    background-color: rgba(2, 87, 93, 0.692);
    color: rgb(255, 255, 255);
}

.lobbyContainer {
    min-height: 52vh;
}

.textAreaGeneral textarea {
    resize: none;
}

.textAreaComment {
    width: 100%;
}


.detailIMG {
    width: 170px;
}

.justifyText {
    text-align: justify;
}

.pText p {
    font-size: 20px;
}

.iconColor {
    color: aliceblue;
    background-color: aliceblue;
}

.greenButton {
    background-color: rgb(0, 128, 55);
    color: aliceblue;
}

.greenButton:hover {
    background-color: rgb(34, 153, 84);
    color: aliceblue;
}

.blueButton {
    background-color: rgb(45, 68, 134);
    color: aliceblue;
}


.blueButton:hover {
    background-color: rgb(62, 91, 171);
    color: aliceblue;
}

.brownButton {
    background-color: rgb(120, 55, 20);
    color: aliceblue;
}

.brownButton:hover {
    background-color: rgb(150, 70, 30);
    color: aliceblue;
}

.orangeButton {
    background-color: rgb(230, 97, 37);
    color: aliceblue;
}

.orangeButton:hover {
    background-color: rgb(242, 120, 59);
    color: aliceblue;
}

.yellowButton {
    background-color: rgb(230, 185, 40)	;
    color: rgb(0, 0, 0);
}

.yellowButton:hover {
    background-color: rgb(255, 215, 60);
    color: rgb(0, 0, 0);
}

.redButton {
    background-color: rgb(180, 30, 30);
    color: aliceblue;
}

.redButton:hover {
    background-color: rgb(220, 50, 50);
    color: aliceblue;
}

.blackButton {
    background-color: rgb(39, 38, 38);
    color: aliceblue;
}

.blackButton:hover {
    background-color: rgb(0, 0, 0);
    color: aliceblue;
}

.grayButton {
    background-color: rgb(198, 197, 197);
    color: rgb(0, 0, 0);
}

.grayButton:hover {
    background-color: rgb(160, 160, 160);
    color: rgb(0, 0, 0);
}

.aquaButton {
    background-color: rgb(20, 130, 120);
    color: aliceblue;
}

.aquaButton:hover {
    background-color: rgb(30, 170, 160);
    color: aliceblue;
}

.purpleButton {
    background-color: rgb(101, 45, 139);
    color: aliceblue;
}

.purpleButton:hover {
    background-color: rgb(123, 59, 167);
    color: aliceblue;
}

.yellowBackground {
    background-color: rgb(241, 195, 29);
    color: rgb(0, 0, 0);
}

.orangeBackground {
    background-color: rgb(230, 97, 37);
    color: aliceblue;
}

.greenBackground {
    background-color: rgb(0, 128, 55);
    color: aliceblue;
}

.blueBackground {
    background-color: rgb(45, 68, 134);
    color: aliceblue;
}


.brownBackground {
    background-color: rgb(120, 55, 20);
    color: aliceblue;
}

.redBackground {
    background-color: rgb(180, 30, 30);
    color: aliceblue;
}

.blackBackground {
    background-color: rgb(39, 38, 38);
    color: aliceblue;
}

.aquaBackground {
    background-color: rgb(20, 130, 120);
    color: aliceblue;
}

.purpleBackground {
    background-color: rgb(101, 45, 139);
    color: aliceblue;
}

.greenTableCell {
    background-color: rgba(76, 175, 80, 0.2);
    color: #2E7D32;
    font-weight: 600;
}

.yellowTableCell {
    background-color: rgba(255, 213, 79, 0.3);
    color: #E65100;
    font-weight: 600;
}

.redTableCell {
    background-color: rgba(239, 83, 80, 0.2);
    color: #B71C1C;
    font-weight: 600;
}

.grayTableCell {
    background-color: rgb(198, 197, 197);
    color: rgb(39, 38, 38);;
    font-weight: 600;
}

.blackHeaderTableText {
    color: #212121;
    font-weight: 600;
}
.greenHeaderTableText {
    color: #2E7D32;
    font-weight: 600;
}

.yellowHeaderTableText {
    color: #F57F17;
    font-weight: 600;
}

.redHeaderTableText {
    color: #D32F2F;
    font-weight: 600;
}

.contPrincipal {
    min-height: 100vh;
    background-color: #f5f5f5;
}

.InputText p {
    font-size: 20px;
    word-wrap: break-word;
}

.InputTextBolder p {
    font-size: 20px;
    font-weight: bolder;
}

.InputTextBolderSimple {
    font-weight: bold;
}

.TitleText p {
    font-size: 30px;
    word-wrap: break-word;
}

.TitleText {
    font-size: 20px;
}

.textTableSize {
    word-wrap: break-word;
    font-size: 21px;
}

.checkBoxTitle {

    font-size: 31px;
    font-weight: bolder;
}

.textRadioButtonSize {
    font-size: 150%;
}

@media screen and (max-width:564px) {
    .ItemImg img {
        object-fit: contain;
    }
}

@media screen and (max-width:1060px)and (min-width:564px) {
    .ItemImg img {
        object-fit: contain;
    }
}

.ItemImg {
    width: 350px;
    height: 350px;


}

.ItemImg img {
    /*object-fit: contain;*/
    width: auto;
    height: 100%;

}

.AlignImgCenter {
    margin: auto;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkboxSize {
    scale: 1.5;
}


.backgroundGreen {
    background-color: rgb(17, 112, 21) !important;
}

.backgroundGray {
    background-color: rgb(197, 197, 197) !important;
}

.backgroundGrayLow {
    background-color: rgb(238, 238, 238) !important;
}

.backgroundOrange {
    background-color: rgb(243, 130, 10) !important;
}

.backgroundBlue {
    background-color: rgb(3, 14, 108) !important;
}

.backgroundWhite {
    background-color: rgb(255, 255, 255) !important;
}

.backgroundSky {
    background-color: rgb(255, 255, 255) !important;
}

.backgroundSky2 p {
    margin-bottom: 0;
}

.backgroundSky2,
.backgroundSky:hover,
.InputTextBolder2 p:hover {
    background-color: rgb(230, 230, 230) !important;
    border-radius: 1rem;
    padding-left: 1rem;
}

.InputTextBolder2 p {
    font-size: 18px;
    font-weight: bolder;
    border-radius: 1rem;
}

.InputTextBolder2 {
    margin-left: 1rem;
    margin-bottom: .2rem;
}

.nonTextDecoration {
    text-decoration: none;
}

.inputResize {
    width: 75px !important;
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .inputResize {
        width: 75px !important;

    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .inputResize {
        width: 75px !important;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1300px) {
    .inputResize {

        width: 75px !important;
    }
}

.colorHead {
    background-color: rgb(30, 38, 146);
    min-height: 15px;
}

.dashboardText {
    font-size: 28px;
}


.softGreen {
    background-color: rgba(3, 241, 90, 0.122) !important;
}

.softYellow {
    background-color: rgba(241, 221, 3, 0.122) !important;
}

.softRed {
    background-color: rgba(241, 3, 3, 0.122) !important;
}

.softGray {
    background-color: rgba(129, 129, 129, 0.122) !important;
}
.input-group-text {
    background: none;
}
.sort-icon {
    font-size: 1.2rem; /* Ajusta el tamaño de los íconos */
    margin: 0 5px; /* Espaciado horizontal entre los íconos */
    cursor: pointer; /* Cambia el cursor para indicar que son clicables */
    color: #fff; /* Asegúrate de que los íconos sean visibles en el fondo */
}


.logoFooter img{
    width: 55px;
    margin-bottom: 1%;
}

.GenFooter{
    width: 100%;
    min-height: 175px;
    clear: both;


    
}

@media screen and (max-width:453px) {
    .logoFooter img{
        width: 50px;
        overflow: hidden;
    }
}
.menuCompany .lateralMenu{
    background: #f4f4f4;
    font-size: 23px;
}
.menuCompany .lateralMenu h4{
    margin-left: 5%;
}
.menuCompany .lateralMenu ul{
    margin-left: 5%;
}
.menuCompany .top-menu{
    background: #f4f4f4;
    transition: background-color 0.3s ease;
}
.menuCompany .top-menu li a:hover{
    background-color: #8c8c8c;
    color: #fff
}
.menuCompany .navbar-brand img{
    width: 89px;
    margin-left: 5%;
}
.menuCompany .filling{
    height: 50px;
}
.menu-text {
    color: #71717a
}
.dropdown .menu-row:hover {
    color: #8c8c8c;
}

.menuCompany .icon {
    color: #1976d2;
}
.menuCompany .icon:hover {
    color: #1565c0;
}
.menuCompany .icon:visited {
    color: #1565c0;
}
.menuCompany .icon:visited:hover {
    color: #1976d2;
}
.navbar-nav .nav-item .menu-row {
    background: #f4f4f4;
    color: #8c8c8c;
}
.navbar-nav .nav-item .menu-row:hover {
    background: #8c8c8c;
    color: #f4f4f4;
}

@media screen and (max-width:613px) {
    .menuCompany .navbar-brand img{
        width: 55px;
        margin-left: 5%;
    }
}

@media screen and (max-width:1060px)and (min-width:589px) {
    .menuCompany .navbar-brand img{
        width: 57px;
        margin-left: 5%;
    }
}


/* Contenedor general */
.container {
    max-width: 1200px;
    margin: auto;
}

.company-title {
    color: #333;
    line-height: 2.25rem;
    margin-bottom: 1rem;
    font-size: 4rem;
    padding-top: 4rem;
    padding-bottom: 2rem;
}

.company-text {
    color: #666;
    line-height: 1.5rem;
    margin-bottom: 2rem;
    font-size: 2rem;
    padding-bottom: 4rem;
}

  /* Tarjetas */
.company-card {
    background-color: #000; /* Fondo negro */
    border: 1px solid #ccc; /* Borde personalizado */
    border-radius: 8px;
    transition: box-shadow 0.3s ease, -webkit-transform 0.3s ease;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    transition: transform 0.3s ease, box-shadow 0.3s ease, -webkit-transform 0.3s ease;
    cursor: pointer;
}

.company-card:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

  /* Cuerpo de la tarjeta */
.card-body {
    padding: 2rem 1rem;
}

  /* Logo de la compañía */
.company-logo img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
    min-height: 100px;
    object-fit: contain;
}

  /* Nombre de la compañía */
.company-name {
    font-size: 1.2rem;
    color: #fff;
    margin: 0;
    font-weight: bold;
    padding-top: 1rem;
}

.main-content {
  flex-grow: 1;
}

#companydash {
  min-height: 100vh;
}



  /* Responsivo */
@media (max-width: 768px) {
    .col-sm-3 {
    flex: 0 0 50%;
    max-width: 50%;
    }
}

@media (max-width: 576px) {
    .col-sm-3 {
    flex: 0 0 100%;
    /* max-width: 100%; */
    }
}


.my-custom-scrollbar {
  position: relative;
  height: 500px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}


.tableFixHead          { overflow: auto; min-height: 10vh; max-height: 70vh; margin-bottom: 5%; z-index: 0; }
.tableFixHead thead th { position: -webkit-sticky; position: sticky; top: 0; z-index: 1; }


.hightSizeTable { min-width:600vh;}

table  { border-collapse: collapse; width: 100%; }
th, td { padding: 8px 16px; }
th     { background:#eee; }
.fs-mini{
  font-size: 12px;
}
.row_hover:hover {
  background-color: rgb(151, 255, 119);
  transition: 0.1s;
}
.row_hover {
  transition: 0.1s;
}




.sticky-col
{
  
  position:-webkit-sticky;
  
  position:sticky;
  left:0px;
  background-color:rgb(255, 255, 255);
  z-index: 4!important;
}
.sticky-col-head
{
  position:-webkit-sticky;
  position:sticky;
  left:0px;
  background-color:rgb(0, 0, 0)!important;
  z-index: 5!important;
}

.tableAnidada{
  margin-top: 35px;
}


.positionStartText{
  text-align: left;
}
.positionEndText{
  text-align: right;
}

.colfix{
  width: 1000px!important;
}

 
.cycle-inventory-card {
  border-radius: 10px;
}

.align-content {
  justify-items: center;
}

.search-input {
  background-color: #fff;
}
.radio-group {
  text-align: start;
}

@media screen and (max-width:613px) {
  .radio-group{
    padding-top: 1rem;
    text-align: center;
  }
}
.inventory-title {
    color: #212121;
}

.inventory-subtitle {
    color: #9e9e9e;
}

.inventory-card {
    border-radius: 10px;
}

.searchPart .col-5{
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
   
}

.searchProd{
    background-color: rgb(1, 91, 22);
    color: aliceblue;
}

.searchProd:hover{
    background-color: rgb(0, 124, 29);
    color: aliceblue;
}

.catalogueOpen{
    background-color: rgb(22, 9, 75);
    color: aliceblue;
}

.catalogueOpen:hover{
    background-color: rgb(37, 15, 129);
    color: aliceblue;
}


.historyProd{
    background-color: rgb(101, 16, 16);
    color: aliceblue;
}

.historyProd:hover{
    background-color: rgb(131, 25, 25);
    color: aliceblue;
}
.titleTotal p{
    font-weight: bold;
    font-size: 25px;
}

.titleDistribution p{
    
    font-size: 20px;
}


.searchPart p{
    
    font-size: 25px;
}

.ItemCodeNumber{
    background-color: rgb(15, 15, 66);
    color: aliceblue;
}


.ItemCodeNumber p{
    margin-top: 19%;
    font-weight: bold;
}

.InventoryIMG{
    width: auto;
    height: 350px;
}
.InventoryIMG img{
    width: 45%;
    height: auto;
}




.productInfoText p{
    font-size: 20px;
}

.desciptioninfo p{
    font-size: 15px;
    margin-top: 3%;
}

.product-input-text {
    background: none;
    border: none;
}

.input-group {
    margin-bottom: 1rem;
}

.action-panel {
    justify-items: center;
}

@media screen and (max-width:613px) {
    .InventoryIMG img{
        width: 60%;
    }
}

@media screen and (max-width:1060px)and (min-width:589px) {
    .InventoryIMG img{
        width: 50%;
    }
}
.ConfirmUpload img{
    background-color: white;
    width: 90%;
}

.scrollBar {
	height: 50vh;
	border: 1px solid #ddd;
	overflow-y: scroll;
}

.FixedTitle{
    overflow: auto; 
    min-height: 87vh; 
    max-height: 87vh; 
    z-index: 0;
}
.FixedTitle2{
    overflow: auto; 
    min-height: 87vh; 
    max-height: 87vh;
    z-index: 0;
}

.PrincipalHead{
    position: -webkit-sticky;
    position: sticky;
    min-height: 15vh;
    max-height: 15vh;
    top: 0; 
    z-index: 1;
    width: 100%;
    background-color: rgb(255, 255, 255);
}

.PrincipalHead2{
    position: -webkit-sticky;
    position: sticky;
    min-height: 12vh;
    max-height: 12vh;
    top: 0; 
    z-index: 1;
    width: 100%;
    background-color: rgb(255, 255, 255);
}

.controlZIndex{
    z-index: 0;
}

.performance{
    width: none;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 1246px) {
    .performance{
        width: none;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .performance{
        width: none;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .performance{
        width: none;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .performance{
        width: none;
    }
}
.imgC{
    text-align: center;
    height: 150px;
    max-width: 100%;
}
.cartaHover:hover{
    background-color:rgb(232, 232, 232);
    transition: 0.2s;
    border-radius: .5rem;
}

.cartaHover{
    font-family:Helvetica;
    transition: 0.2s;
}

.item-card {
    align-items: center;
}
.ItemImage{
    width: auto;
    height: 350px;
}

.ItemImage img{
    width: auto;
    height: 100%;
}

.image-box {
    justify-items: center;
}

@media only screen and (max-width:576px) {
    .ItemImage  {
        margin-top: 30px;
        margin-bottom: 12px;
    }
}

.my-custom-scrollbar {
    position: relative;
    height: 500px;
    overflow: auto;
  }
  .table-wrapper-scroll-y {
    display: block;
  }
  
  
  .tableFixHeadSmall          { overflow: auto; min-height: 10vh; max-height: 45vh; margin-bottom: 5%; z-index: 0; }
  .tableFixHeadSmall thead th { position: -webkit-sticky; position: sticky; top: 0; z-index: 1; }
  
  
  table  { border-collapse: collapse; width: 100%; }
  th, td { padding: 8px 16px; }
  th     { background:#eee; }
  .fs-mini{
    font-size: 12px;
  }
  .row_hover:hover {
    background-color: rgb(151, 255, 119);
    transition: 0.1s;
  }
  .row_hover {
    transition: 0.1s;
  }

.react-calendar {
    
    max-width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
  }
  
  .react-calendar--doubleView {
    width: 700px;
  }
  
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    box-sizing: border-box;
  }
  
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  
  .react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
    background-color: #eeeeee;
    color: rgb(0, 0, 0);
  }
  .react-calendar__navigation span{

    color: rgb(0, 0, 0);
  }
  .react-calendar__navigation button{

    color: rgb(0, 0, 0);
  }
  
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }
  
  

  .salesCalendar{
    color: #81c784;
    font-weight: bold;
    font-size: small;
    text-align: center;
  }
  .quantSalesCalendar{
    color: #64b5f6;
    font-weight: bold;
    font-size: small;
    text-align: center;
  }
  .color-box{
    width: 20px;
    height: 20px;

    display: inline-block;
    margin-right: 10px;
  }
  .text-blockLeyend{
    display: inline-block;
    text-align: left;
  }

  .backLeyendBlue{
    background: #64b5f6;
  }
  .backLeyendGreen{
    background:#81c784;
    
  }

  
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e0e0e0;
  }
  
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
    background-color: rgb(0, 0, 0);
    color: #e6e6e6;
  }

  .weeknoview {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
    background-color: rgb(0, 0, 0);
    color: #e6e6e6;
  }
  
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    border: 1px solid #000000;

  }
  
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
    border: 1px solid #000000;
    max-height:  101.4px;
  }
  .react-calendar__month-view__weekNumbers{
    order: 2;
  }
  
  .react-calendar__month-view__days__day--weekend {
    color: #e57373;
  }
  
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #000000;
  }

   .react-calendar__month-view__days__day {
    border: 1px solid black !important;
  }
  
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  
  .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: left;
    line-height: 16px;
    font-size: smaller;
    font-weight: bold;
  }
  
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }
  
  .react-calendar__tile--now {
    background: #ffff76;
  }
  
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
  }
  
  .react-calendar__tile--hasActive {
    background: #76baff;
  }
  
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  
  .react-calendar__tile--active {
    background: #ffebee;
    color: #f44336
  }
  
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #ffcdd2;
    color: #f44336
  }
  
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
  
.lateralMenu {
    background:#0d47a1;
    font-size: 20px;
}

.lateralMenu h4,
.lateralMenu-altitude h4{
    margin-left: 5%;
}
.lateralMenu ul,
.lateralMenu-altitude ul{
    margin-left: 5%;
}

.dropDownMenu{
    background:#0d47a1;

}
.dropdown-menu.dropDownMenu.show {
    border: none;
}

.dropdown-menu li {
    margin: 0px 10px;
    padding: 3px 5px;
}

.dropdown-menu li a {
    border-radius: 15px;
}

.navbar-nav .nav-item.dropdown,
.navbar-nav .nav-item-altitude.dropdown {
    margin: 4px 0px;
}

.navbar-nav .nav-item .nav-link,
.navbar-nav .nav-item-altitude .nav-link {
    border-radius: 15px;
    padding: 5px;
}

.dropDownMenu li a:hover{
    background:#1976d2;
}

.navbar-nav .nav-item .nav-link.show,
.navbar-nav .nav-item .nav-link.active,
.navbar-nav .nav-item .nav-link:hover {
    background:#1976d2;
    color: #fff;
}

/* Altitude begins */

.lateralMenu-altitude {
    font-size: 20px;
}

.navbar-nav .nav-item-altitude .nav-link.show,
.navbar-nav .nav-item-altitude .nav-link.active,
.navbar-nav .nav-item-altitude .nav-link:hover {
    background:#757575;
    color: #fff;
}



.lateralMenu-altitude,
.dropDownMenu-altitude {
    background: #212121;
}

.dropDownMenu-altitude li a:hover{
    background:#757575;
}

/* Altitude finish */
.navbar-brand img{
    width: 180px;
    margin-left: 5%;
}

@media only screen and (min-width: 300px) {
    .navbar-brand img{
        width: 90.1px;
        margin-left: 5%;
    }
}

@media only screen and (min-width: 375px) {
    .navbar-brand img{
        width: 100.1px;
        margin-left: 5%;
    }
}
@media only screen and (min-width: 414px) {
    .navbar-brand img{
        width: 120.1px;
        margin-left: 5%;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .navbar-brand img{
        width: 189.1px;
        margin-left: 5%;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .navbar-brand img{
        width: 189.1px;
        margin-left: 5%;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1300px) {
    .navbar-brand img{
        width: 189.1px;
        margin-left: 5%;
    }
}

.filling{
    height: 35px;
    
}
